.--nav-link {
  display: block;
  margin: 1rem 0rem;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 9999px;
}
@media screen and (min-width: 1024px) {
  .--nav-link {
    margin: 1rem 0.75rem;
  }
}
.--nav-link.--active {
  background-color: #8cc63f;
  color: white;
  font-weight: bold;
}
.--title {
  text-transform: uppercase;
  font-family: "LemonMilk", ui-sans-serif, system-ui;
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
}
@media screen and (min-width: 768px) {
  .--title {
    font-size: 1.875rem;
    /* 30px */
    line-height: 2.25rem;
    /* 36px */
  }
}
@media screen and (min-width: 1024px) {
  .--title {
    font-size: 2.25rem;
    /* 36px */
    line-height: 2.5rem;
    /* 40px */
  }
}
@media screen and (min-width: 1280px) {
  .--title {
    font-size: 3.75rem;
    /* 60px */
    line-height: 1;
  }
}
.--bg-cover {
  background-image: url("cover.01f63645.png");
  background-size: cover;
}
.--bg-contact-us {
  background-image: url("contact-us.14cc7228.png");
  background-size: cover;
}
.--bg-privacy-policy {
  background: #27aae1;
  background: linear-gradient(90deg, #27aae1 0%, #2e3192 100%);
}
header {
  height: 6rem;
  padding-top: 1rem;
}
@media screen and (min-width: 640px) {
  header {
    height: 8rem;
    padding-top: 3.5rem;
  }
}
@media screen and (min-width: 768px) {
  header {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 1024px) {
  header {
    padding-top: 1.5rem;
  }
}
header.shrink {
  height: 4rem;
}
@media screen and (min-width: 640px) {
  header.shrink {
    height: 6rem;
    padding-top: 2rem;
  }
}
@media screen and (min-width: 768px) {
  header.shrink {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 1024px) {
  header.shrink {
    padding-top: 1.5rem;
  }
}
#about-us .--about-us-left {
  opacity: 0%;
  right: 1rem;
}
@media screen and (min-width: 768px) {
  #about-us .--about-us-left {
    flex: 0 0 38%;
  }
}
#about-us .--about-us-right {
  opacity: 0%;
  left: 1rem;
}
@media screen and (min-width: 768px) {
  #about-us .--about-us-right {
    flex: 0 0 62%;
  }
}
#about-us.active .--about-us-left {
  opacity: 100%;
  right: 0;
}
@media screen and (min-width: 768px) {
  #about-us.active .--about-us-left {
    flex: 0 0 38%;
  }
}
#about-us.active .--about-us-right {
  opacity: 100%;
  left: 0;
}
@media screen and (min-width: 768px) {
  #about-us.active .--about-us-right {
    flex: 0 0 62%;
  }
}
#product .--line {
  flex: 1 1 0;
  height: 2px;
  background-color: #2e3293;
}
#product .--product-image-card {
  width: 80vw;
  height: 80vw;
  background-color: white;
  border-radius: 4rem 4rem 0 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 640px) {
  #product .--product-image-card {
    width: 30rem;
    height: 30rem;
  }
}
@media screen and (min-width: 768px) {
  #product .--product-image-card {
    width: 16rem;
    height: 16rem;
  }
}
@media screen and (min-width: 1024px) {
  #product .--product-image-card {
    width: 20rem;
    height: 20rem;
    box-shadow: 0.75rem 0.75rem 1rem rgba(0, 0, 0, 0.25);
  }
}
@media screen and (min-width: 1280px) {
  #product .--product-image-card {
    width: 20rem;
    height: 20rem;
    box-shadow: 0.75rem 0.75rem 1rem rgba(0, 0, 0, 0.25);
  }
}
@media screen and (min-width: 1536px) {
  #product .--product-image-card {
    width: 25rem;
    height: 25rem;
    box-shadow: 0.75rem 0.75rem 1rem rgba(0, 0, 0, 0.25);
  }
}
#product .--product-item {
  width: 100%;
}
#product .--product-item .--text {
  flex: 4;
  position: relative;
  opacity: 0%;
  left: 1rem;
  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 300ms;
}
#product .--product-item.active .--text {
  opacity: 100%;
  left: 0;
}
#contact-us .--input {
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
}
#contact-us > * {
  opacity: 0%;
}
#contact-us.active > * {
  opacity: 100%;
}
#contact-us-form button > * {
  display: none;
}
#contact-us-form button .initial-text {
  display: block;
}
#contact-us-form.loading .initial-text {
  display: none;
}
#contact-us-form.loading .spinner {
  display: block;
}
#contact-us-form.success .initial-text {
  display: none;
}
#contact-us-form.success .checkmark {
  display: block;
}
#contact-us-form.error button {
  background-color: red;
}
#contact-us-form.error .initial-text {
  display: none;
}
#contact-us-form.error .error-text {
  display: block;
}
.container.squeeze {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (min-width: 640px) {
  .container.squeeze {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (min-width: 1280px) {
  .container.squeeze {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  stroke-width: 2;
  stroke: #8cc63f;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px white;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
          animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@-webkit-keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px white;
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px white;
  }
}
/*# sourceMappingURL=index.4d3eba4a.css.map */
