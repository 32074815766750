$primary: #2e3293;
$primary-green: #8cc63f;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

.--nav-link {
  display: block;
  margin: 1rem 0rem;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 9999px;

  @media screen and (min-width: $lg) {
    margin: 1rem 0.75rem;
  }

  &.--active {
    background-color: $primary-green;
    color: white;
    font-weight: bold;
  }
}

.--title {
  text-transform: uppercase;
  font-family: 'LemonMilk', ui-sans-serif, system-ui;

  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */

  @media screen and (min-width: $md) {
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
  }

  @media screen and (min-width: $lg) {
    font-size: 2.25rem; /* 36px */
    line-height: 2.5rem; /* 40px */
  }

  @media screen and (min-width: $xl) {
    font-size: 3.75rem; /* 60px */
    line-height: 1;
  }
}

.--bg-cover {
  background-image: url('../images/cover.png');
  background-size: cover;
}

.--bg-contact-us {
  background-image: url('../images/contact-us.png');
  background-size: cover;
}

.--bg-privacy-policy {
  background: rgb(39,170,225);
  background: linear-gradient(90deg, rgba(39,170,225,1) 0%, rgba(46,49,146,1) 100%);
}

header {
  height: 6rem;
  padding-top: 1rem;

  @media screen and (min-width: $sm) {
    height: 8rem;
    padding-top: 3.5rem;
  }

  @media screen and (min-width: $md) {
    padding-top: 2.5rem;
  }

  @media screen and (min-width: $lg) {
    padding-top: 1.5rem;
  }

  &.shrink {
    height: 4rem;

    @media screen and (min-width: $sm) {
      height: 6rem;
      padding-top: 2rem;
    }

    @media screen and (min-width: $md) {
      padding-top: 2.5rem;
    }

    @media screen and (min-width: $lg) {
      padding-top: 1.5rem;
    }
  }
}

#about-us {
  .--about-us-left {
    opacity: 0%;
    right: 1rem;
    @media screen and (min-width: $md) {
      flex: 0 0 38%;
    }
  }
  
  .--about-us-right {
    opacity: 0%;
    left: 1rem;
    @media screen and (min-width: $md) {
      flex: 0 0 62%;
    }
  }

  &.active {
    .--about-us-left {
      opacity: 100%;
      right: 0;
      @media screen and (min-width: $md) {
        flex: 0 0 38%;
      }
    }
    
    .--about-us-right {
      opacity: 100%;
      left: 0;
      @media screen and (min-width: $md) {
        flex: 0 0 62%;
      }
    }
  }
}

#product {
  .--line {
    flex: 1 1 0;
    height: 2px;
    background-color: $primary;
  }
  
  .--product-image-card {
    width: 80vw;
    height: 80vw;
    background-color: white;
    border-radius: 4rem 4rem 0 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0.3rem 0.3rem 1rem rgb(0 0 0 / 0.25);

    @media screen and (min-width: $sm) {
      width: 30rem;
      height: 30rem;
    }

    @media screen and (min-width: $md) {
      width: 16rem;
      height: 16rem;
    }

    @media screen and (min-width: $lg) {
      width: 20rem;
      height: 20rem;
      box-shadow: 0.75rem 0.75rem 1rem rgb(0 0 0 / 0.25);
    }

    @media screen and (min-width: $xl) {
      width: 20rem;
      height: 20rem;
      box-shadow: 0.75rem 0.75rem 1rem rgb(0 0 0 / 0.25);
    }

    @media screen and (min-width: $xxl) {
      width: 25rem;
      height: 25rem;
      box-shadow: 0.75rem 0.75rem 1rem rgb(0 0 0 / 0.25);
    }
  }

  .--product-item {
    width: 100%;

    .--text {
      flex: 4;
      position: relative;
      opacity: 0%;
      left: 1rem;
      transition-property: all;
      transition-timing-function: linear;
      transition-duration: 300ms;
    }

    &.active {
      .--text {
        opacity: 100%;
        left: 0;
      }
    }
  }
}

#contact-us {
  .--input {
    background-color: white;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.5rem 1rem;
  }

  &>* {
    opacity: 0%;
  }

  &.active {
    &>* {
      opacity: 100%;
    }
  }
}

#contact-us-form {
  button {
    &>* {
      display: none;
    }
    .initial-text {
      display: block;
    }
  }
  
  &.loading {
    .initial-text {
      display: none;
    }
    .spinner {
      display: block;
    }
  }

  &.success {
    .initial-text {
      display: none;
    }
    .checkmark {
      display: block;
    }
  }

  &.error {
    button {
      background-color: red;
    }
    .initial-text {
      display: none;
    }
    .error-text {
      display: block;
    }
  }
}

.container.squeeze {
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: $sm) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media screen and (min-width: $xl) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  stroke-width: 2;
  stroke: $primary-green;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px white;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px white;
  }
}
